import React from "react"

import Layout from "../components/layout"
import SEOComponent from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const Bio = () => (
  <Layout pageName="Bio">
    <SEOComponent  title="Bio" />
    <div className="columns m-0 pt-4">
      <div className="column is-one-third">
        <StaticImage src="../images/pob.jpg" alt="Paul's profile pic in cartoony 3D style" />
      </div>
      <div className="column">
        <p>My name is Paul O’Brien, and I am a 3D artist specializing in cartoon character modeling and rigging. I create custom 3D models for use in video games, animation, or 3D printing. I am the owner of Boston Cartoon Company. This is my personal art site.</p>
      </div>
    </div>
  </Layout>
)

export default Bio